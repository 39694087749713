import React from 'react';
import { ButtonBase, Grid } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import Image from 'components/common/Image';
import TextTransformer from 'components/common/TextTransformer';

type StylePropsType = {
  backgroundColor: string;
  isLargeCard: boolean;
};

interface EventPromoCardProps {
  title: string;
  // eslint-disable-next-line react/no-unused-prop-types
  backgroundColor: string;
  backgroundImage: string;
  objectPosition?: string;
  isLargeCard: boolean;
  route: {
    link: string;
  };
}

const useStyles = makeStyles<StylePropsType>()((theme, props) => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    minHeight: '296px',
    maxHeight: '328px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },
  },
  mainContainer: {
    borderRadius: 8,
    display: 'inline-block',
    overflow: 'hidden',
    height: '100%',
    backgroundColor: props.backgroundColor,
    '&:hover': {
      backgroundColor: alpha(props.backgroundColor, 0.8),
    },
  },
  imageContainer: {
    height: '160px !important',
  },
  imageContainerLarge: {
    height: '200px !important',
    [theme.breakpoints.down('sm')]: {
      height: '160px !important',
    },
  },
  imageInner: {
    borderRadius: '0 !important',
    height: '160px !important',
  },
  imageInnerLarge: {
    borderRadius: '0 !important',
    height: '200px !important',
    [theme.breakpoints.down('sm')]: {
      height: '160px !important',
    },
  },
  textInnerContainer: {
    padding: theme.spacing(2, 2),
  },
  title: {
    color: 'white',
    width: '100%',
    fontWeight: 500,
    fontSize: 24,
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontWeight: '500',
    },
  },
  viewAll: {
    textTransform: 'capitalize',
    color: 'white',
    alignSelf: 'left',
    fontSize: 15,
    fontWeight: 500,
    padding: theme.spacing(0, 0),
  },
}));

const EventPromoCard: React.FC<React.PropsWithChildren<EventPromoCardProps>> = (props) => {
  const { title, backgroundImage, objectPosition, route, isLargeCard } = props;
  const { link } = route;
  const { classes } = useStyles(props, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props,
  });
  const [t] = useTranslation('public');

  return (
    <ButtonBase className={classes.root} href={link}>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} className={isLargeCard ? classes.imageContainerLarge : classes.imageContainer}>
          <Image
            className={isLargeCard ? classes.imageInnerLarge : classes.imageInner}
            variant="wide"
            rounded
            src={backgroundImage}
            objectPosition={objectPosition}
            alt={title}
            priority
          />
        </Grid>
        <Grid container item xs={12} className={classes.textInnerContainer}>
          <TextTransformer
            align="center"
            paragraph
            className={classes.title}
            limit={100}
            defaultFontSize="h4"
            minFontSize="h4"
            transformAfter={21}
            component="h1"
            transform={false}
          >
            {t(`screen.homepage.${title}`)}
          </TextTransformer>
          <TextTransformer align="left" className={classes.viewAll} limit={100}>
            {t<string>('common:button.viewEvents')}
          </TextTransformer>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default EventPromoCard;
