const BUILD_ENV = process.env.NEXT_PUBLIC_BUILD_ENV;
const isTestEnv = () => BUILD_ENV === 'test';
const isProductionEnv = () => BUILD_ENV === 'production';

export const socialEventsTypeFilter = () => {
  let socialEventsTypeId = 88;
  if (isTestEnv()) {
    socialEventsTypeId = 116;
  } else if (isProductionEnv()) {
    socialEventsTypeId = 88;
  }
  return {
    type: socialEventsTypeId,
  };
};

export const artsCultureTypeFilter = () => ({
  category: 2,
});

export const jewishHolidayFilter = () => ({
  category: 46,
});

export const foodFilter = () => ({
  category: 51,
});

export const jewishLearningFilter = () => ({
  category: 56,
});
