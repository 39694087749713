import React from 'react';
import { Field, Form } from 'formik';
import { Grid, InputAdornment } from '@mui/material';
import { MergedProps } from 'components/common/withFormSubmission';
import { FieldToTextField } from 'components/common/FormField';
import { useTranslation } from 'i18n';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomTextField from 'components/common/CustomTextField';
import { makeStyles } from 'tss-react/mui';
import withResetPasswordForm, { IProps, IValues } from './withResetPasswordFrom';

const useStyles = makeStyles()((theme) => ({
  inputFiled: {
    '&& .MuiFormLabel-root': {
      color: `${theme.palette.text.primary} !important`,
      fontSize: '13px !important',
      fontWeight: 600,
    },
  },
}));

const ResetPasswordForm: React.FC<React.PropsWithChildren<MergedProps<IProps, IValues>>> = () => {
  const [t] = useTranslation('public');
  const { classes } = useStyles();

  const [showPasswordOne, setShowPasswordOne] = React.useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = React.useState(false);

  const handleClickShowPasswordOne = () => {
    setShowPasswordOne((show) => !show);
  };

  const handleMouseDownPasswordOne = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPasswordTwo = () => setShowPasswordTwo((show) => !show);

  const handleMouseDownPasswordTwo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Grid container spacing={5} component={Form}>
      <Grid item xs={12}>
        <Field
          className={classes.inputFiled}
          fullWidth
          name="password_one"
          label={t('common:field.label.create_new_password_one')}
          type={showPasswordOne ? 'text' : 'password'}
          helperTextBottom
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordOne}
                  onMouseDown={handleMouseDownPasswordOne}
                  edge="end"
                  size="small"
                >
                  {showPasswordOne ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          component={FieldToTextField}
          TextField={CustomTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Field
            className={classes.inputFiled}
            fullWidth
            name="password_two"
            label={t('common:field.label.create_new_password_two')}
            type={showPasswordTwo ? 'text' : 'password'}
            helperTextBottom
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordTwo}
                    onMouseDown={handleMouseDownPasswordTwo}
                    edge="end"
                    size="small"
                  >
                    {showPasswordTwo ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            component={FieldToTextField}
            TextField={CustomTextField}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withResetPasswordForm(ResetPasswordForm);
