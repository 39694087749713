import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import palette from 'theme/palette';
import Button from 'components/common/Button';
import CircleSector from 'components/common/CircleSector';

interface LoggedOutFavoritePopUpProps {
  small?: boolean;
  onClose: () => void;
  onLogin: () => void;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(2),
    },
  },
  small: {
    padding: theme.spacing(4),
  },
  text: {
    color: 'white',
    fontWeight: 500,
  },
  laterButton: {
    color: '#fff',
    '&:hover': {
      border: '2px solid #fff',
    },
  },
}));

const LoggedOutFavoritePopUp: React.FC<React.PropsWithChildren<LoggedOutFavoritePopUpProps>> = ({
  small,
  onClose,
  onLogin,
}) => {
  const { classes, cx } = useStyles();
  const [t] = useTranslation('public');

  const onLoginClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onLogin();
  };
  const onCloseClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClose();
  };
  return (
    <>
      <CircleSector
        variant="bottom-left"
        position="absolute"
        backgroundColor={palette.primary.light}
        opacity={0.2}
        radius={154}
        top={0}
        right={0}
      />
      <CircleSector
        variant="bottom-left"
        position="absolute"
        backgroundColor={palette.primary.extraLight}
        opacity={0.2}
        radius={99}
        top={0}
        right={0}
      />
      <Grid
        container
        className={cx(classes.root, { [classes.small]: small })}
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid item container justifyContent="center" alignItems="center">
          <Grid item xs={9}>
            <Typography paragraph className={classes.text} variant="body1" align="center">
              {t<string>('eventCard.statement')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
              <Grid item xs={10}>
                <Button
                  className={classes.laterButton}
                  fullWidth
                  rounded
                  color="info"
                  size="small"
                  variant="outlined"
                  onClick={(event) => onCloseClick(event)}
                >
                  {t<string>('common:button.later')}
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Button
                  fullWidth
                  rounded
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={(event) => onLoginClick(event)}
                >
                  {t<string>('common:button.login')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LoggedOutFavoritePopUp;
