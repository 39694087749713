import { trackPageView } from 'api/mixpanel';
import { getContext } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { getParsedCookie } from '../../utils/cookie';
import { isAlive } from '../../store/features/auth/utils/sessionCookie';
import { Expirable } from '../../store/features/auth/types';

const currentPageKey = 'currentPage';

const getAccessToken = () => {
  const next: any = getContext('next');
  const parsedCookie = getParsedCookie(next);

  if (parsedCookie.access) {
    const access = jwtDecode(parsedCookie.access);
    if (isAlive(access as Expirable)) return parsedCookie.access;
  }
};

export function trackPage(currentPage: string, params?: {}, shouldTrack = true) {
  const prevPage = sessionStorage.getItem(currentPageKey) || 'Entry';
  const accessToken = getAccessToken();

  if (shouldTrack) {
    const trackPage = async () => {
      try {
        (window as any).mixpanel?.track('Mixpanel Page Viewed', {
          source: prevPage,
          page_name: currentPage,
          ...params,
          'Logged Status': accessToken ? 'Logged In' : 'Logged Out',
        });
        await trackPageView({
          ...(accessToken && {
            headers: {
              Authorization: `JWT ${accessToken}`,
            },
          }),
          body: {
            page_name: currentPage,
            params: {
              source: prevPage,
              ...params,
            },
          },
        });
      } catch (error) {
        console.log('error', error);
      }
    };
    trackPage().then(() => sessionStorage.setItem(currentPageKey, currentPage));
  } else {
    sessionStorage.setItem(currentPageKey, currentPage);
  }
}
