import React, { memo } from 'react';
import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';

export interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number;
  label: number;
}

const CircularProgressWithLabel: React.FC<React.PropsWithChildren<CircularProgressWithLabelProps>> = (props) => {
  const { value, label, className, ...rest } = props;

  return (
    <Box display="inline-flex" className={className}>
      <CircularProgress variant="determinate" value={value} {...rest} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(CircularProgressWithLabel);
