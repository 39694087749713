import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'i18n';
import { routes } from 'utils/routing';
import { isFetchedStates, isFetchingStates } from 'store/utils';
import { MARKETS_SLUGS_MAP } from 'utils/transform';

import { EventsSelectors } from 'store/selectors';
import { EventsActions } from 'store/actions';

import { useLoader } from 'hooks';

import EventsSection from 'components/common/EventsSection';
import RenderComponent from 'components/common/RenderComponent';
import { Grid } from '@mui/material';

interface IProps {
  market: string;
  titleKey: string;
  ordering: string;
  itemsPerPage: number;
  context: string;
  additionalFilters?: {};
  contentLoadingState?: (context: string, isLoaded: boolean, hasItems: boolean) => void;
}

const EventsSectionContainer: React.FC<React.PropsWithChildren<IProps>> = ({
  market,
  titleKey,
  itemsPerPage,
  ordering,
  additionalFilters = {},
  context,
  contentLoadingState,
}) => {
  const [t] = useTranslation('public');
  const dispatch = useDispatch();
  const market_slug = useMemo(() => MARKETS_SLUGS_MAP[market as keyof typeof MARKETS_SLUGS_MAP], [market]);
  const queryParams = useMemo(
    () => ({
      exclude_hidden_events: true,
      ordering,
      market_slug,
      page_size: 12,
      context,
      ...additionalFilters,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ordering, market_slug],
  );

  const handleToggleFavoriteEvent = useCallback(
    (eventId: any) => dispatch(EventsActions.toggleFavoriteEvent.request({ id: eventId })),
    [dispatch],
  );

  const stateSelector = useMemo(() => EventsSelectors.contextualEventsState(), []);
  const { state } = useLoader({
    actions: EventsActions.getContextualEvents,
    stateSelector,
    options: {
      queryParams,
    },
  });

  useEffect(() => {
    if (contentLoadingState) {
      contentLoadingState(context, isFetchedStates(state), state.entities.length > 0);
    }
  }, [contentLoadingState, context, state]);

  return (
    <RenderComponent
      isLoading={isFetchingStates(state)}
      renderContent={() =>
        state.entities.length > 0 && (
          <Grid item xs={12} style={{ paddingTop: 24 }}>
            <EventsSection
              title={t(titleKey)}
              events={state.entities}
              itemsPerPage={itemsPerPage}
              onToggleFavoriteEvent={handleToggleFavoriteEvent}
              {...routes.events(queryParams)}
            />
          </Grid>
        )
      }
    />
  );
};

export default EventsSectionContainer;
