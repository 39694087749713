import React, { forwardRef } from 'react';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  IconButtonTypeMap,
  ExtendButtonBase,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type CustomProps = {
  disableHoverRipple?: boolean;
};

export type IconButtonProps<D extends React.ElementType = IconButtonTypeMap['defaultComponent']> = MuiIconButtonProps<
  D,
  CustomProps
>;

const useStyles = makeStyles()({
  disableHoverRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const IconButton = forwardRef(
  <D extends React.ElementType = IconButtonTypeMap['defaultComponent']>(
    props: IconButtonProps<D>,
    ref: React.Ref<any>,
  ) => {
    const { disableHoverRipple, className, ...other } = props;
    const { classes, cx } = useStyles();
    return (
      <MuiIconButton
        ref={ref}
        {...other}
        className={cx({ [classes.disableHoverRipple]: disableHoverRipple }, className)}
      />
    );
  },
);

export default IconButton as ExtendButtonBase<IconButtonTypeMap<CustomProps>>;
