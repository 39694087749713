import React from 'react';
import { NextPage } from 'next';
import { useSelector } from 'react-redux';
import { useAuthFlow, useMagicLinkAuthProvider, useModal } from 'hooks';
import { makeEntitiesLoader } from 'hooks/makeSimpleLoader';

import { MarketsActions } from 'store/actions';
import { DetectMarketSelectors, MarketsSelectors } from 'store/selectors';
import { isFetchingStates } from 'store/utils';
import { UserHeader } from 'components/common/Header';
import HomepageLayout from 'components/home/HomepageLayout';
import Footer from 'components/home/Footer';
import RenderComponent from 'components/common/RenderComponent';
import ResetPasswordModal from 'components/modals/ResetPasswordModal';
import useResetPasswordProvider from 'hooks/auth/useResetPasswordProvider';

const params = {
  page_size: 100,
};
const useMarketsState = makeEntitiesLoader(MarketsActions.getMarkets, MarketsSelectors.marketsState);

const Index: NextPage = () => {
  useAuthFlow();
  useMagicLinkAuthProvider();
  const [showResetPasswordModal] = useModal(ResetPasswordModal);
  useResetPasswordProvider(showResetPasswordModal);

  const market = useSelector(DetectMarketSelectors.getSelectedMarket);
  const marketsState = useMarketsState(() => params, []);

  return (
    <>
      <UserHeader />
      <RenderComponent
        isLoading={isFetchingStates(marketsState) && !market}
        renderContent={() => <HomepageLayout markets={marketsState.entities} market={market} />}
      />
      <Footer />
    </>
  );
};

export default Index;
