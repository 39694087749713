import { useEffect, useState } from 'react';

const useLoggedOutFavoriteEvent = () => {
  const [loggedOutFavoriteEvent, setLoggedOutFavoriteEvent] = useState(null);

  const handleResetLoggedOutFavoriteEvent = () => {
    setLoggedOutFavoriteEvent(null);
  };

  useEffect(() => {
    if (loggedOutFavoriteEvent) {
      const popOutTimer = setTimeout(() => setLoggedOutFavoriteEvent(null), 7000);
      return () => clearTimeout(popOutTimer);
    }
  }, [loggedOutFavoriteEvent, setLoggedOutFavoriteEvent]);

  return {
    loggedOutFavoriteEvent,
    setLoggedOutFavoriteEvent,
    handleResetLoggedOutFavoriteEvent,
  };
};

export default useLoggedOutFavoriteEvent;
