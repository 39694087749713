import {
  artsCultureTypeFilter,
  foodFilter,
  jewishHolidayFilter,
  jewishLearningFilter,
  socialEventsTypeFilter,
} from './HomePageCategoriesHelper';

export interface EventsSection {
  titleKey: string;
  ordering: string;
  context: string;
  filter: {};
}

const socialEventsSection = (): EventsSection => ({
  titleKey: 'screen.homepage.socialEvents',
  ordering: 'session_date',
  context: 'socialEvents',
  filter: socialEventsTypeFilter(),
});

const artsCultureSection = (): EventsSection => ({
  titleKey: 'screen.homepage.artsCulture',
  ordering: 'session_date',
  context: 'artsCulture',
  filter: artsCultureTypeFilter(),
});

const jewishHolidaySection = (): EventsSection => ({
  titleKey: 'screen.homepage.jewishHoliday',
  ordering: 'session_date',
  context: 'jewishHoliday',
  filter: jewishHolidayFilter(),
});

const foodSection = (): EventsSection => ({
  titleKey: 'screen.homepage.food',
  ordering: 'session_date',
  context: 'food',
  filter: foodFilter(),
});

const jewishLearningSection = (): EventsSection => ({
  titleKey: 'screen.homepage.jewishLearning',
  ordering: 'session_date',
  context: 'jewishLearning',
  filter: jewishLearningFilter(),
});

const shuffledArray = (array: EventsSection[]): EventsSection[] => {
  let currentIndex = array.length;
  let randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
};

export const shuffledHomepageSections = (): EventsSection[] =>
  shuffledArray([
    socialEventsSection(),
    artsCultureSection(),
    jewishHolidaySection(),
    foodSection(),
    jewishLearningSection(),
  ]);
