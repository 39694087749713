import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ResponsiveGrid from 'components/common/ResponsiveGrid';
import Image from 'components/common/Image';

interface IProps {
  logos: any[];
  title: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

const useStyles = makeStyles()((theme) => ({
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: 100,
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.subtitle2,
      marginBottom: theme.spacing(5),
    },
  },
}));

const TitledLogosList: React.FC<React.PropsWithChildren<IProps>> = ({ logos, title }) => {
  const { classes } = useStyles();
  return (
    <ResponsiveGrid container mdSpacing={9} xsSpacing={2} justifyContent="center">
      <ResponsiveGrid item>
        <Typography
          variant="h5"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          className={classes.subtitle}
        >
          {title}
        </Typography>
      </ResponsiveGrid>
      <ResponsiveGrid item xs={12} style={{ padding: '36px 72px' }}>
        <ResponsiveGrid container mdSpacing={5} xsSpacing={10} justifyContent="center" alignItems="center">
          {logos.map((logo, index) => (
            <ResponsiveGrid
              item
              xs={6}
              md={index > 9 && index < 14 ? 2.5 : 2.2}
              container
              key={logo}
              justifyContent="center"
            >
              <Image
                variant="rectangle"
                objectFit="contain"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                className={classes.image}
                src={logo}
                alt={logo}
              />
            </ResponsiveGrid>
          ))}
        </ResponsiveGrid>
      </ResponsiveGrid>
    </ResponsiveGrid>
  );
};

export default TitledLogosList;
