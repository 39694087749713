import React from 'react';
import { useTranslation } from 'i18n';
import TitledLogosList from 'components/common/TitledLogosList';

const supportersLogos = [
  '/static/svg/homepage/supporters/logo1.svg',
  '/static/svg/homepage/supporters/logo2.svg',
  '/static/svg/homepage/supporters/logo3.svg',
  '/static/svg/homepage/supporters/logo4.svg',
  '/static/svg/homepage/supporters/logo5.svg',
  '/static/svg/homepage/supporters/logo6.svg',
  '/static/svg/homepage/supporters/logo7.svg',
  '/static/svg/homepage/supporters/logo8.svg',
  '/static/svg/homepage/supporters/logo9.svg',
  '/static/svg/homepage/supporters/logo10.svg',
  '/static/svg/homepage/supporters/logo11.svg',
  '/static/svg/homepage/supporters/logo12.svg',
  '/static/svg/homepage/supporters/logo13.svg',
  '/static/svg/homepage/supporters/logo14.svg',
  '/static/svg/homepage/supporters/logo15.png',
  '/static/svg/homepage/supporters/logo16.svg',
  '/static/svg/homepage/supporters/logo17.svg',
];

const SupportersTittledLogosList = () => {
  const [t] = useTranslation('public');
  return <TitledLogosList title={t<string>('screen.homepage.subtitle.madePossible')} logos={supportersLogos} />;
};

export default SupportersTittledLogosList;
