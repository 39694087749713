import { useRouter } from 'next/router';
import { useEffect } from 'react';

const isValidQueryParam = (param: string | string[]) => typeof param === 'string' && param.length > 0;

const requiredQueryKeys = ['uid', 'token'];

const useResetPasswordProvider = (showResetPasswordModal: any) => {
  const router = useRouter();
  const hasRequiredKeys = requiredQueryKeys.every((key) => isValidQueryParam(router.query[key]));

  useEffect(() => {
    if (hasRequiredKeys) {
      const { uid, token } = router.query;
      showResetPasswordModal({ uid, token });
    }
  }, [hasRequiredKeys, router.query, showResetPasswordModal]);
};

export default useResetPasswordProvider;
