import React, { forwardRef } from 'react';
import { Grid, GridProps, GridSpacing } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const SPACINGS = [0, 1, 2, 2.25, 3, 4, 5, 6, 7, 8, 9, 10] as const;
const BREAKPOINT_KEYS = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

type CustomSpacings = {
  xsSpacing?: GridSpacing;
  smSpacing?: GridSpacing;
  mdSpacing?: GridSpacing;
  lgSpacing?: GridSpacing;
  xlSpacing?: GridSpacing;
};

interface IProps extends GridProps {
  component?: React.ElementType;
}

const getPropKey = (breakpointKey: Breakpoint) => `${breakpointKey}Spacing` as keyof CustomSpacings;
const getStyleKey = (breakpointKey: Breakpoint, spacing: GridSpacing) => `${breakpointKey}-spacing-${spacing}`;

const useStyles = makeStyles()((theme) => {
  const styles: any = {
    item: {},
  };

  BREAKPOINT_KEYS.forEach((key) => {
    SPACINGS.forEach((spacing) => {
      const themeSpacing = parseInt(theme.spacing(spacing), 10);
      if (themeSpacing === 0) return;

      styles[getStyleKey(key, spacing)] = {
        [theme.breakpoints.up(key)]: {
          width: `calc(100% + ${themeSpacing}px)`,
          margin: -themeSpacing / 2,
          '& > .MuiGrid-item': {
            padding: themeSpacing / 2,
          },
        },
      };
    });
  });

  return styles;
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ResponsiveGrid: React.FC<React.PropsWithChildren<IProps & CustomSpacings>> = forwardRef(
  ({ className, item, ...props }, ref) => {
    const { classes, cx } = useStyles();
    const classNames: string[] = [];

    BREAKPOINT_KEYS.forEach((key) => {
      const propKey = getPropKey(key);
      const spacing = props[propKey];

      if (spacing) {
        delete props[propKey];
        // TODO: makeStyles
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        classNames.push(classes[getStyleKey(key, spacing)]);
      }
    });

    return (
      <Grid
        ref={ref}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        className={cx({ [classes.item]: item }, classNames, className)}
        item={item}
        {...props}
      />
    );
  },
);

export default ResponsiveGrid;
