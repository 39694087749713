import React, { memo } from 'react';
import { makeStyles } from 'tss-react/mui';

import CircularProgressWithLabel, { CircularProgressWithLabelProps } from './CircularProgressWithLabel';

const useStyles = makeStyles()(() => ({
  circleDeterminate: {
    transitionDelay: '0ms',
    transitionDuration: '1000ms',
    transitionTimingFunction: 'linear',
  },
}));

const CircularTimer: React.FC<React.PropsWithChildren<CircularProgressWithLabelProps>> = (props) => {
  const { classes } = useStyles();
  return <CircularProgressWithLabel {...props} classes={classes} />;
};

export default memo(CircularTimer);
